import React from 'react';
import { Code2, Palette, Server, Cog, Users, Terminal } from 'lucide-react';

const skills = [
  {
    name: "UI/UX Design",
    icon: <Palette className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: [
      "Figma",
      "Prototyping",
      "Wireframing",
      "Interaction Design",
      "UX Research",
      "Google UX Certificate"
    ],
  },
  {
    name: "Web Development",
    icon: <Code2 className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: [
      "React.js",
      "Next.js",
      "Tailwind CSS",
      "Material UI",
      "Bulma CSS",
      "Django",
      "Express.js",
      "JavaScript",
      "Python",
      "API Development"
    ],
  },
  {
    name: "Database and Cloud Management",
    icon: <Server className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: [
      "MongoDB",
      "PostgreSQL",
      "AWS Glue",
      "AWS Redshift",
      "AWS S3"
    ],
  },
  {
    name: "Mechanical Design",
    icon: <Cog className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: [
      "SolidWorks",
      "AutoCAD",
      "CATIA",
      "Siemens NX",
      "Ansys",
      "GD&T",
      "3D Printing"
    ],
  },
  {
    name: "DevOps and Tools",
    icon: <Terminal className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: [
      "JIRA",
      "Git",
      "Linux",
      "AWS",
      "Azure"
    ],
  },
  {
    name: "Soft Skills",
    icon: <Users className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: [
      "Problem-Solving",
      "Leadership",
      "Time Management",
      "Effective Communication",
      "Adaptability"
    ],
  },
];


export function Skills() {
  return (
    <section id="skills" className="py-12 bg-gray-50 dark:bg-gray-900 transition-colors duration-300">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-900 dark:text-white">My Skills</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {skills.map((skill, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col justify-between h-full"
            >
              <div className="p-6 flex-grow">
                <div className="flex items-center mb-4">
                  <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded-full">
                    {skill.icon}
                  </div>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  {skill.name}
                </h3>
                <div className="flex flex-wrap gap-2">
                  {skill.technologies.map((tech, techIndex) => (
                    <span
                      key={techIndex}
                      className="text-sm bg-gray-200 text-gray-800 px-3 py-1 rounded-full dark:bg-gray-700 dark:text-gray-200"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
